@mixin text-wariant($parent, $bg){
  a.#{$parent}:hover{
    background-color: darken($bg, $color-percent);
  }
  a.text-#{$parent}:hover{
    color: darken($bg, $color-percent);
  }
  .text-#{$parent},
  .text-#{$parent}-hover a:hover{
    color: $bg !important;
  }
}

@include text-wariant(primary, $primary);
@include text-wariant(warn, $warn);
@include text-wariant(accent, $accent);
@include text-wariant(success, $success);
@include text-wariant(info, $info);
@include text-wariant(warning, $warning);
@include text-wariant(danger, $danger);
@include text-wariant(dark, $dark);
@include text-wariant(blue, $blue);

.text-white {
  color: $full-white !important;
}
.text-white-lt{
  color: $light-white;
}
.text-white-dk{
  color: $dark-white;
}
.text-black {
  color: $full-black;
}
.text-black-lt {
  color: $light-black;
}
.text-black-dk {
  color: $dark-black;
}
.text-muted {
  color: inherit !important;
  opacity: 0.6;
}
.text-color{
  color: $text-color;
}

._100{
  font-weight: 100;
}
._200{
  font-weight: 200;
}
._300{
  font-weight: 300;
}
._400{
  font-weight: 400;
}
._500{
  font-weight: 500;
}
._600{
  font-weight: 600;
}
._700{
  font-weight: 700;
}
._800{
  font-weight: 800;
}

.text{
  font-size: 1rem;
}
.text-xs{
  font-size: $font-size-xs;
}
.text-sm{
  font-size: $font-size-sm;
}
.text-md{
  font-size: $font-size-md;
}
.text-lg{
  font-size: $font-size-lg;
}

.text-2x{
  font-size: 2em;
}
.text-3x{
  font-size: 3em;
}
.text-4x{
  font-size: 4em;
}

.l-h{
  line-height: $line-height;
}
.l-h-1x{
  line-height: 1;
}
.l-h-2x{
  line-height: 2em;
}

.l-s-1x{
  letter-spacing: 1px;
}
.l-s-2x{
  letter-spacing: 2px;
}
.l-s-3x{
  letter-spacing: 3px;
}
.l-s-4x{
  letter-spacing: 4px;
}

.l-s-n-1x{
  letter-spacing: -1px;
}
.l-s-n-2x{
  letter-spacing: -2px;
}
.l-s-n-3x{
  letter-spacing: -3px;
}
.l-s-n-4x{
  letter-spacing: -4px;
}

.h-1x{
  height: $line-height;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}
.h-2x{
  height: $line-height * $font-size * 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.h-3x{
  height: $line-height * $font-size * 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.h-4x{
  height: $line-height * $font-size * 4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.text-u-c{
  text-transform: uppercase;
}
.text-l-t{
  text-decoration: line-through;
}
.text-u-l{
  text-decoration: underline;
}

.text-ellipsis{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}

.text-shadow{
  text-shadow: 0 1px 0 rgba(0,0,0,.1);
}
