.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

[ui-view].ng-leave {
  display: none;
}


[ui-view].ng-leave.smooth {
  display: block;
}

.smooth.ng-animate{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

// big animation
.fade-in-right-big.ng-enter {
  -webkit-animation: fadeInRightBig 0.5s;
  animation: fadeInRightBig 0.5s;
}
.fade-in-right-big.ng-leave {
  -webkit-animation: fadeOutLeftBig 0.5s;
  animation: fadeOutLeftBig 0.5s;
}

.fade-in-left-big.ng-enter {
  -webkit-animation: fadeInLeftBig 0.5s;
  animation: fadeInLeftBig 0.5s;
}
.fade-in-left-big.ng-leave {
  -webkit-animation: fadeOutRightBig 0.5s;
  animation: fadeOutRightBig 0.5s;
}

.fade-in-up-big.ng-enter {
  -webkit-animation: fadeInUpBig 0.5s;
  animation: fadeInUpBig 0.5s;
}
.fade-in-up-big.ng-leave {
  -webkit-animation: fadeOutUpBig 0.5s;
  animation: fadeOutUpBig 0.5s;
}

.fade-in-down-big.ng-enter {
  -webkit-animation: fadeInDownBig 0.5s;
  animation: fadeInDownBig 0.5s;
}
.fade-in-down-big.ng-leave {
  -webkit-animation: fadeOutDownBig 0.5s;
  animation: fadeOutDownBig 0.5s;
}

// small
.fade-in.ng-enter {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}
.fade-in.ng-leave {
  -webkit-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
}

.fade-in-right.ng-enter {
  -webkit-animation: fadeInRight 0.5s;
  animation: fadeInRight 0.5s;
}
.fade-in-right.ng-leave {
  -webkit-animation: fadeOutLeft 0.5s;
  animation: fadeOutLeft 0.5s;
}

.fade-in-left.ng-enter {
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}
.fade-in-left.ng-leave {
  -webkit-animation: fadeOutRight 0.5s;
  animation: fadeOutRight 0.5s;
}

.fade-in-up.ng-enter {
  -webkit-animation: fadeInUp 0.5s;
  animation: fadeInUp 0.5s;
}
.fade-in-up.ng-leave {
  -webkit-animation: fadeOutUp 0.5s;
  animation: fadeOutUp 0.5s;
}

.fade-in-down.ng-enter {
  -webkit-animation: fadeInDown 0.5s;
  animation: fadeInDown 0.5s;
}
.fade-in-down.ng-leave {
  -webkit-animation: fadeOutDown 0.5s;
  animation: fadeOutDown 0.5s;
}
