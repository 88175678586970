.progress {
	border-radius: .25rem;
	overflow: hidden;
	background-color: $min-black;
}

.progress-xxs{
  height: 4px;
}

.progress-xs{
  height: 8px;
}

.progress-sm{
  height: 12px;
  font-size: 0.8em;
}
