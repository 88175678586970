.item{
  position: relative;
  .top{
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
  }
  .bottom{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .center{
    position: absolute;
    right: 0;
    left: 0;
    top: 42%;
  }
}
.item-overlay{
  position: absolute;
  right: 0;
  left: 0;
  display: none;
  &.active,
  .item:focus,
  .item:hover & {
    display: block;
  }
  &.w-full{
    text-align: center;
    top: 0;
    bottom: 0;
  }
}
.item-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.item-bg img{
  position: absolute;
  width: 110%;
  left: 50%;
  top: 50%;
  margin-left: -55%;
  margin-top:  -55%;
}

.blur{
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.blur-5{
  opacity: 0.5;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.opacity{
  opacity: 0.5;
}

.opacity-3{
  opacity: 0.3;
}
