.modal.animate{
  .modal-dialog{
    @include translate(0, 0);
  }

  .fade-right-big {
    -webkit-animation: fadeOutLeftBig 0.5s;
    animation: fadeOutLeftBig 0.5s;
  }

  .fade-left-big {
    -webkit-animation: fadeOutRightBig 0.5s;
    animation: fadeOutRightBig 0.5s;
  }

  .fade-up-big {
    -webkit-animation: fadeOutUpBig 0.5s;
    animation: fadeOutUpBig 0.5s;
  }

  .fade-down-big {
    -webkit-animation: fadeOutDownBig 0.5s;
    animation: fadeOutDownBig 0.5s;
  }

  .fade-right {
    -webkit-animation: fadeOutLeft 0.5s;
    animation: fadeOutLeft 0.5s;
  }

  .fade-left {
    -webkit-animation: fadeOutRight 0.5s;
    animation: fadeOutRight 0.5s;
  }

  .fade-up{
    -webkit-animation: fadeOutUp 0.5s;
    animation: fadeOutUp 0.5s;
  }

  .fade-down {
    -webkit-animation: fadeOutDown 0.5s;
    animation: fadeOutDown 0.5s;
  }

  .flip-x{
    -webkit-animation: flipOutX 0.5s;
    animation: flipOutX 0.5s;
  }

  .flip-y{
    -webkit-animation: flipOutY 0.5s;
    animation: flipOutY 0.5s;
  }

  .zoom{
    -webkit-animation: zoomOut 0.5s;
    animation: zoomOut 0.5s;
  }

  .roll{
    -webkit-animation: rollOut 0.5s;
    animation: rollOut 0.5s;
  }

  .bounce{
    -webkit-animation: bounceOut 0.5s;
    animation: bounceOut 0.5s;
  }

  .rotate{
    -webkit-animation: rotateOut 0.5s;
    animation: rotateOut 0.5s;
  }

  &.in,
  &.show{
    .fade-right-big{
      -webkit-animation: fadeInRightBig 0.5s;
      animation: fadeInRightBig 0.5s;
    }
    .fade-left-big{
      -webkit-animation: fadeInLeftBig 0.5s;
      animation: fadeInLeftBig 0.5s;
    }
    .fade-up-big {
      -webkit-animation: fadeInUpBig 0.5s;
      animation: fadeInUpBig 0.5s;
    }
    .fade-down-big {
      -webkit-animation: fadeInDownBig 0.5s;
      animation: fadeInDownBig 0.5s;
    }

    .fade-right{
      -webkit-animation: fadeInRight 0.5s;
      animation: fadeInRight 0.5s;
    }
    .fade-left{
      -webkit-animation: fadeInLeft 0.5s;
      animation: fadeInLeft 0.5s;
    }
    .fade-up {
      -webkit-animation: fadeInUp 0.5s;
      animation: fadeInUp 0.5s;
    }
    .fade-down {
      -webkit-animation: fadeInDown 0.5s;
      animation: fadeInDown 0.5s;
    }

    .flip-x{
      -webkit-animation: flipInX 0.5s;
      animation: flipInX 0.5s;
    }
    .flip-y{
      -webkit-animation: flipInY 0.5s;
      animation: flipInY 0.5s;
    }

    .zoom{
      -webkit-animation: zoomIn 0.5s;
      animation: zoomIn 0.5s;
    }
    .roll{
      -webkit-animation: rollIn 0.5s;
      animation: rollIn 0.5s;
    }
    .bounce{
      -webkit-animation: bounceIn 0.5s;
      animation: bounceIn 0.5s;
    }
    .rotate{
      -webkit-animation: rotateIn 0.5s;
      animation: rotateIn 0.5s;
    }


  }

}
