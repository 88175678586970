/*
Flexbox
*/
[layout],
[data-layout] {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; 
}

[layout=column],
[data-layout=column] {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; 
}

[layout=row],
[data-layout=row] {
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row; 
}

[flex],
[data-flex] {
  box-sizing: border-box;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1; 
}
